.blink-text {
    animation-name: blinker;
    animation-duration: 4s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    font-size: 72px;
    font-weight: 700;
}

@keyframes blinker {  
    0% { opacity: 1.0; }
    50% { opacity: 0.0; }
    100% { opacity: 1.0; }
}